import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import type { ActionFunctionArgs, MetaFunction } from "@remix-run/node";
import { json } from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { z } from "zod";

import { handleNewSession } from "./login.server";
import { GeneralErrorBoundary } from "~/components/error-boundary";
import { CheckboxField, ErrorList, Field } from "~/components/forms";
import { SlimLayout } from "~/components/marketing/lib/SlimLayout";
import Logo from "~/components/ui/logo";
import { StatusButton } from "~/components/ui/status-button";
import { login, requireAnonymous } from "~/lib/auth.server";
import { ProviderConnectionForm, providerNames } from "~/lib/connections";
import { validateCSRF } from "~/lib/csrf.server";
import { checkHoneypot } from "~/lib/honeypot.server";
import { useIsPending } from "~/lib/misc";
import { userPrefs } from "~/lib/user-prefs.server";
import { AccountsService } from "~/models/accounts/accountsService.server";

const LoginFormSchema = z.object({
  username: z.string(),
  password: z.string(),
  redirectTo: z.string().optional(),
  remember: z.boolean().optional(),
});

export async function loader({ request }: ActionFunctionArgs) {
  await requireAnonymous(request);

  return json({});
}

export async function action({ request }: ActionFunctionArgs) {
  await requireAnonymous(request);
  const formData = await request.formData();
  await validateCSRF(formData, request.headers);
  checkHoneypot(formData);
  const submission = await parseWithZod(formData, {
    schema: (intent) =>
      LoginFormSchema.transform(async (data, ctx) => {
        // if (intent?.type !== ) return { ...data, session: null };

        const session = await login(data);
        if (!session) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid username or password",
          });

          return z.NEVER;
        }

        return {
          ...data,
          session,
        };
      }),
    async: true,
  });

  if (submission.status !== "success" || !submission.value.session) {
    return json(
      { result: submission.reply({ hideFields: ["password"] }) },
      { status: submission.status === "error" ? 400 : 200 },
    );
  }

  const { session, remember, redirectTo } = submission.value;

  const cookieSession = await userPrefs.getSession(
    request.headers.get("Cookie"),
  );

  if (
    !cookieSession.has("accountId") ||
    cookieSession.get("accountId") === "none"
  ) {
    // fetch user accounts, grab one
    const {
      data: { accountUsers },
    } = await AccountsService.collections
      .userAccounts({
        userId: session.userId,
      })
      .go();

    cookieSession.set("accountId", accountUsers[0]?.accountId ?? "none");
  }

  return handleNewSession({
    request,
    session,
    remember: remember ?? false,
    redirectTo,
    responseHeaders: [
      ["Set-Cookie", await userPrefs.commitSession(cookieSession)],
    ],
  });
}

export default function LoginPage() {
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  const [form, fields] = useForm({
    id: "login-form",
    constraint: getZodConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: LoginFormSchema });
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <SlimLayout>
      <div className="flex">
        <Link to="/" aria-label="Home" className="w-full">
          <div className="flex justify-center">
            <Logo
              variant="mark-word"
              className="w-auto text-card-foreground h-12"
            />
          </div>
        </Link>
      </div>
      <h2 className="mt-20 text-lg font-semibold text-gray-900">
        Sign in to your account
      </h2>
      <p className="mt-2 text-sm text-gray-700">
        Don’t have an account?{" "}
        <Link
          to="/auth/register"
          className="font-medium text-blue-600 hover:underline"
        >
          Sign up
        </Link>{" "}
        for a free trial.
      </p>
      <Form
        method="POST"
        {...getFormProps(form)}
        className="mt-10 grid grid-cols-1 gap-y-4"
      >
        <AuthenticityTokenInput />
        <HoneypotInputs />

        <Field
          labelProps={{ children: "Username" }}
          inputProps={{
            ...getInputProps(fields.username, { type: "text" }),
            autoFocus: true,
            className: "lowercase",
          }}
          errors={fields.username.errors}
        />

        <Field
          labelProps={{ children: "Password" }}
          inputProps={getInputProps(fields.password, {
            type: "password",
          })}
          errors={fields.password.errors}
        />

        <div className="flex justify-between">
          <CheckboxField
            labelProps={{
              htmlFor: fields.remember.id,
              children: "Remember me",
            }}
            buttonProps={getInputProps(fields.remember, {
              type: "checkbox",
            })}
            errors={fields.remember.errors}
          />
          <div>
            <Link
              to="/auth/forgot-password"
              className="text-body-xs font-semibold"
            >
              Forgot password?
            </Link>
          </div>
        </div>

        <input {...getInputProps(fields.redirectTo, { type: "hidden" })} />
        <ErrorList errors={form.errors} id={form.errorId} />

        <div className="flex items-center justify-between gap-6 pt-3">
          <StatusButton
            className="w-full"
            status={isPending ? "pending" : form.status ?? "idle"}
            type="submit"
            disabled={isPending}
          >
            Log in
          </StatusButton>
        </div>
      </Form>
      {!!providerNames.length && (
        <ul className="mt-5 flex flex-col gap-5 border-b-2 border-t-2 border-border py-3">
          {providerNames.map((providerName) => (
            <li key={providerName}>
              <ProviderConnectionForm
                type="Login"
                providerName={providerName}
                redirectTo={redirectTo}
              />
            </li>
          ))}
        </ul>
      )}
      <div className="flex items-center justify-center gap-2 pt-6">
        <span className="text-muted-foreground">New here?</span>
        <Link
          to={
            redirectTo
              ? `/auth/register?${encodeURIComponent(redirectTo)}`
              : "/auth/register"
          }
        >
          Create an account
        </Link>
      </div>
    </SlimLayout>
  );
}

export const meta: MetaFunction = () => {
  return [{ title: "Login to CandidateStream" }];
};

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
